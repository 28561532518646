import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import DataContext from "../../../context/DataContext";
import { SupplyProps, VendorProps } from "../../../types/interfaces";
import Styles from "./DashboardComponents.module.css";
import { BarcodeOutlined } from "@ant-design/icons";
import { NON_DONATED_ITEM_INITIAL_STATE } from "../../../utils/data";

export const NonDonatedItemsForm = () => {
  const [form] = Form.useForm();
  const {
    nonDonateditemValues,
    onGenerateBarcode,
    onChangeNonDonatedItemValues,
    dataOptions,
  } = useContext(DataContext);
  const [buttonGenerateDisabled, setButtonGenerateDisabled] = useState(true);
  const [supplyOptions, setSupplyOptions] = useState<SupplyProps[]>([]);
  const [vendorOptions, setVendorOptions] = useState<VendorProps[]>([]);

  useEffect(() => {
    if (nonDonateditemValues.supply && nonDonateditemValues.vendor)
      setButtonGenerateDisabled(false);
  }, [nonDonateditemValues.supply, nonDonateditemValues.vendor]);

  useEffect(() => {
    if (dataOptions.nonDonatedItems.length > 0) {
      setSupplyOptions(
        dataOptions.nonDonatedItems.map((supply: SupplyProps) => {
          return {
            ...supply,
            value: supply.id.toString(),
          };
        })
      );
    }
    if (dataOptions.vendorOptions.length > 0) {
      setVendorOptions([
        { value: "Not Defined", id: 0, label: "Not Defined" },
        ...dataOptions.vendorOptions.map((vendor: VendorProps) => {
          return {
            ...vendor,
            value: vendor.id.toString(),
          };
        }),
      ]);
    }
    onChangeNonDonatedItemValues(NON_DONATED_ITEM_INITIAL_STATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());
  return (
    <>
      <Form
        layout="vertical"
        initialValues={nonDonateditemValues}
        form={form}
        onFinish={() => onGenerateBarcode("nonDonatedItem")}
        className={Styles.form_item}
      >
        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="1. Supply"
              name="supply"
              rules={[{ required: true, message: "Supply Required" }]}
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Supply"
                optionFilterProp="children"
                value={nonDonateditemValues.supply}
                onChange={(value) =>
                  onChangeNonDonatedItemValues({
                    ...nonDonateditemValues,
                    supply: value,
                  })
                }
                filterOption={filterOption}
                options={supplyOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="2. Vendor"
              name="vendor"
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a Vendor"
                optionFilterProp="children"
                value={nonDonateditemValues.vendor}
                onChange={(value) =>
                  onChangeNonDonatedItemValues({
                    ...nonDonateditemValues,
                    vendor: value,
                  })
                }
                filterOption={filterOption}
                options={vendorOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            className={Styles.form_item}
            icon={<BarcodeOutlined />}
            disabled={buttonGenerateDisabled}
          >
            Generate Barcode
          </Button>
        </Row>
      </Form>
    </>
  );
};
