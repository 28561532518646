import { Button, Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ButtonList } from "./ButtonList";
import { ItemList } from "./ItemList";
import Styles from "./AdminComponents.module.css";
import DataContext from "../../../context/DataContext";
import { SupplyProps, VendorProps } from "../../../types/interfaces";
import { AddToDbForm } from "../dashboard/AddToDbForm";
import { CustomModal } from "../../ui";

export const NonDonatedItemsAdminForm = () => {
  const {
    dataOptions,
    addModalOpen,
    isLoading,
    onCancelToDb,
    onAddToDb,
    onCreateBarcode,
    handleAddToDb,
  } = useContext(DataContext);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedSupply, setSelectedSupply] = useState<SupplyProps>({
    id: 0,
    label: "",
    value: "",
  });
  const [selectedVendor, setSelectedVendor] = useState<VendorProps>({
    id: 0,
    label: "",
    value: "",
  });

  useEffect(() => {
    if (selectedSupply) setButtonDisabled(false);
  }, [selectedSupply]);

  const onChangeSupply = (supply: SupplyProps) => {
    setSelectedSupply(supply);
  };

  const onChangeVendor = (vendor: VendorProps) => {
    setSelectedVendor(vendor);
  };

  const createBarcode = () => {
    onCreateBarcode("nonDonatedItem", {
      supply_id: selectedSupply.id,
      vendor_id: selectedVendor.id,
    });
  };

  return (
    <div className={Styles.AdminPage}>
      <Row gutter={[16, 24]} justify="center" align="middle">
        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col span={24}>
              <ButtonList title="Supply" onClick={() => onAddToDb("supply")} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemList
                data={dataOptions.nonDonatedItems}
                onItemClick={onChangeSupply}
                active={selectedSupply}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col span={24}>
              <ButtonList title="Vendor" onClick={() => onAddToDb("vendor")} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemList
                data={dataOptions.vendorOptions}
                onItemClick={onChangeVendor}
                active={selectedVendor}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 24]} justify="center">
            <Button
              type="primary"
              disabled={buttonDisabled}
              onClick={createBarcode}
              loading={isLoading}
            >
              Create Barcode
            </Button>
          </Row>
        </Col>
      </Row>
      <CustomModal
        title="Add Attribute to the database"
        isModalOpen={addModalOpen}
        handleOk={onCancelToDb}
        handleCancel={onCancelToDb}
        footer={null}
      >
        <AddToDbForm
          addDataToDb={(label: string, next: () => void) => {
            handleAddToDb({ label });
            next();
          }}
          options={[]}
        />
      </CustomModal>
    </div>
  );
};
