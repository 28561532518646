import { List } from "antd";
import React, { useContext } from "react";
import Styles from "./AdminComponents.module.css";
import { GeneralProps } from "../../../types/interfaces";
import clsx from "clsx";
import DataContext from "../../../context/DataContext";

interface Props {
  data: GeneralProps[];
  onItemClick?: (item: any) => void;
  active: any;
}

export const ItemList = ({ data, onItemClick, active }: Props) => {
  const { isLoading } = useContext(DataContext);
  const handleItemClick = (item: GeneralProps) => {
    onItemClick && onItemClick(item);
  };
  return (
    <>
      <List
        loading={isLoading}
        size="small"
        className={Styles.list}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={() => handleItemClick(item)}
            onDoubleClick={() =>
              handleItemClick({ id: 0, label: "", value: "" })
            }
            className={clsx(
              Styles.item_list,
              active.label === item.label && Styles.item_list_active
            )}
          >
            {item.label}
          </List.Item>
        )}
      />
    </>
  );
};
