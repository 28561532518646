import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../context/DataContext";
import { AttributeProps, GeneralProps } from "../../../types/interfaces";

interface Props {
  addDataToDb: (label: string, next: () => void) => void;
  options: { label: string; value: string }[];
  attributeSelected?: GeneralProps | null;
  addExistingAttributes?: (selectedAttributes: string[]) => void;
}

export const AddToDbForm = ({
  attributeSelected,
  addDataToDb,
  options,
  addExistingAttributes,
}: Props) => {
  const [form] = Form.useForm();
  const { onCancelToDb } = useContext(DataContext);
  const [label, setLabel] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  useEffect(() => {
    form.setFieldsValue({ label: "" });
    setLabel("");
    setSelectedAttributes([]);
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({ label: "" });
    setLabel("");
    setSelectedAttributes([]);
  }, []);

  const handleCancel = () => {
    onCancelToDb();
    resetForm();
  };

  const resetForm = () => {
    setLabel("");
    setSelectedAttributes([]);
    form.setFieldsValue({ label: "" });
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());

  return (
    <>
      <Divider>Create New Attribute(s)</Divider>
      <Form
        layout="vertical"
        initialValues={{ label }}
        form={form}
        onFinish={() => {
          addDataToDb(label, resetForm);
          setLabel("");
          setSelectedAttributes([]);
        }}
      >
        To create multiple attributes, separate them by a comma.
        <Row
          gutter={16}
          justify="center"
          align="middle"
          style={{ marginTop: "10px" }}
        >
          <Col span={24}>
            <Form.Item
              name="label"
              rules={[
                {
                  required: true,
                  message: "Please input description",
                },
              ]}
            >
              <Input
                placeholder="Name of the attribute"
                name="label"
                value={label}
                onChange={(event) => setLabel(event.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle">
          <Col>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!(label.length > 0)}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      {options?.length > 0 && attributeSelected?.id ? (
        <>
          <Divider>Or Select Previously Created</Divider>
          <Row gutter={16} justify="center" align="middle">
            <Col>
              <Form.Item
                label="Attributes"
                className="modal-filter-report__form-item"
                name="selectedAttributes"
              >
                <Select
                  mode="multiple"
                  style={{ width: 300 }}
                  allowClear
                  className="modal-filter-report__block"
                  options={options}
                  placeholder="Select Attributes"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={(value) => setSelectedAttributes(value)}
                  value={selectedAttributes}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify="center" align="middle">
            <Col>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  addExistingAttributes &&
                  addExistingAttributes(selectedAttributes)
                }
                disabled={!(selectedAttributes.length > 0)}
              >
                Add Existing
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
    </>
  );
};
