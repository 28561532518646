import { Modal } from "antd";
import React, { ReactNode } from "react";

interface Props {
  title: string;
  children: ReactNode;
  isModalOpen: boolean;
  footer?: ReactNode[] | null;
  width?: number;
  handleCancel?: () => void;
  handleOk: () => void;
}
export const CustomModal = ({
  title,
  children,
  isModalOpen,
  footer,
  width,
  handleOk,
  handleCancel,
}: Props) => {
  return (
    <Modal
      width={width}
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
    >
      {children}
    </Modal>
  );
};
