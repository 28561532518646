import React, { ReactNode } from "react";
import { Layout, theme } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { DisplayComponent } from "./DisplayComponent";
import { AppHeader } from "../components/AppHeader";
import { AppContent } from "../components/AppContent";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const {
    token: { colorFill },
  } = theme.useToken();

  return (
    <>
      <Layout>
        <Header
          style={{
            padding: 10,
            background: colorFill,
            minHeight: "12vh",
          }}
        >
          <AppHeader />
        </Header>
        <DisplayComponent error={false} isLoading={false}>
          <Content>
            <AppContent>{children}</AppContent>
          </Content>
        </DisplayComponent>
        <Footer
          style={{
            textAlign: "center",
            background: colorFill,
            color: "#FFF",
            minHeight: "2vh",
          }}
        >
          ©Copyright Quattro Software, {new Date().getFullYear()}
        </Footer>
      </Layout>
    </>
  );
};
