import React, { useContext } from "react";
import { CustomModal } from "../ui";
import AuthContext from "../../context/AuthContext";
import { Button, Typography } from "antd";

export const HelpModal = ({ content }: { content: string }) => {
  const { isHelpModalOpen, onOpenHelpModal } = useContext(AuthContext);
  return (
    <CustomModal
      title="Help"
      isModalOpen={isHelpModalOpen}
      handleCancel={() => onOpenHelpModal(false)}
      handleOk={() => {}}
      footer={[
        <Button
          onClick={() => {
            onOpenHelpModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          onClick={() => {
            const token = localStorage.getItem("info");
            window.open(
              "https://support.quattroapps.app/support?app=custom&auth=" + token
            );
            onOpenHelpModal(false);
          }}
        >
          Go to Support Page
        </Button>,
      ]}
    >
      <Typography>
        {content}
        {
          ", if any further support is required, please go to Quattro Support page"
        }
      </Typography>
    </CustomModal>
  );
};
