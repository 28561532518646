import React, { useContext } from "react";
import { Col, Flex, Row } from "antd";
import logo from "../../assets/images/company_logo.png";
import { useNavigate } from "react-router-dom";
import { ItemForm } from "../../components/pages";
import DataContext from "../../context/DataContext";

export const AppHeader = () => {
  const navigate = useNavigate();
  const { onChangeItemSource } = useContext(DataContext);
  return (
    <Row justify="space-evenly" align={"top"}>
      <Col xs={6} sm={12} md={20}>
        <img
          src={logo}
          alt="logo"
          className="img-background"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/dashboard")}
        />
      </Col>
      <Col xs={6} sm={12} md={4}>
        <ItemForm onChangeItem={onChangeItemSource} />
      </Col>
    </Row>
  );
};
