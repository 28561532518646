import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { BarcodeFile } from "./files";
import { ItemProps } from "../../types/interfaces";

export const PDFView = ({
  barCodeString,
  qty,
  barcodeData,
}: {
  barCodeString: string;
  qty: number;
  barcodeData: ItemProps;
}) => {
  return (
    <PDFViewer height={"95%"} width={"95%"}>
      <BarcodeFile
        barCodeString={barCodeString}
        qty={qty}
        barcodeData={barcodeData}
      />
    </PDFViewer>
  );
};
