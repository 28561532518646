import { Button, Form, Input, Row } from "antd";
import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { ColComponent } from "../../ui/ColComponent";

export const LoginForm = () => {
  const { onLogin } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  return (
    <Row gutter={[24, 0]} justify="center" align="middle">
      <ColComponent xl={16} xs={24}>
        <Form onFinish={() => onLogin(username, pass)} layout="vertical">
          <Form.Item
            label="User"
            name="user"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              placeholder="User"
              name="user"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              value={pass}
              onChange={(event) => setPass(event.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "50%" }}>
              SIGN IN
            </Button>
          </Form.Item>
        </Form>
      </ColComponent>
    </Row>
  );
};
