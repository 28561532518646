import React, { useContext, useEffect, useState } from "react";
import {
  DonatedItemsAdminForm,
  NonDonatedItemsAdminForm,
  SearchBarcodeComponent,
  SearchTemplateComponent,
} from "../../components/pages";
import { HelpModal } from "../../components/utils";
import DataContext from "../../context/DataContext";
import { Alert, FloatButton, Popover, Tooltip } from "antd";
import { CustomModal } from "../../components/ui";
import { SearchOutlined, ExportOutlined } from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { QuestionCircleOutlined, BarcodeOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const helpContent =
  "Choose one Item from list to remove, or add new elements to the lists";

export const AdminPage = () => {
  const {
    isError,
    errorMessage,
    isSuccess,
    successMessage,
    getData,
    itemSource,
  } = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTemplateOpen, setIsModalTemplateOpen] = useState(false);
  const { isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) navigate("/dashboard");
    getData({ endpoint: "options" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div>
      <Paragraph>Step 1: Select item source</Paragraph>
      <Paragraph>Step 2: Select attributes</Paragraph>
      <Paragraph>Step 3: Click on Create Barcode</Paragraph>
      <Paragraph>Step 4: Open Downloaded file</Paragraph>
    </div>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Title level={2}>Settings</Title>
        <span style={{ marginLeft: "5px" }}>
          <Popover content={content} title="Hint">
            <QuestionCircleOutlined />
          </Popover>
        </span>
      </div>

      <>
        {itemSource && (
          <>
            {itemSource.label === "Donation" ? (
              <DonatedItemsAdminForm />
            ) : (
              <NonDonatedItemsAdminForm />
            )}
          </>
        )}
      </>

      <FloatButton.Group shape="circle" style={{ right: 40, top: "15vh" }}>
        <Tooltip title="Search Barcodes by date">
          <FloatButton
            icon={<SearchOutlined />}
            type="primary"
            onClick={() => setIsModalOpen(true)}
          />
        </Tooltip>
        <Tooltip title="Get Oddo Templates">
          <FloatButton
            icon={<ExportOutlined />}
            type="primary"
            onClick={() => setIsModalTemplateOpen(true)}
          />
        </Tooltip>
        <Tooltip title="See All Barcodes">
          <FloatButton
            icon={<BarcodeOutlined />}
            type="primary"
            onClick={() => navigate("/barcode")}
          />
        </Tooltip>
      </FloatButton.Group>
      <CustomModal
        title={"Filter By Date"}
        isModalOpen={isModalOpen}
        handleOk={() => setIsModalOpen(false)}
        handleCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <SearchBarcodeComponent itemSource={itemSource} />
      </CustomModal>
      <CustomModal
        title={"Odoo Templates"}
        isModalOpen={isModalTemplateOpen}
        handleOk={() => setIsModalTemplateOpen(false)}
        handleCancel={() => setIsModalTemplateOpen(false)}
        footer={null}
      >
        <SearchTemplateComponent itemSource={itemSource} />
      </CustomModal>
      <HelpModal content={helpContent} />
      {isError && (
        <Alert
          message={errorMessage}
          banner
          type="error"
          closable
          className="alert"
        />
      )}
      {isSuccess && (
        <Alert
          message={successMessage}
          banner
          type="success"
          closable
          className="alert"
        />
      )}
    </>
  );
};
