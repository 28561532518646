import React, { useContext, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "antd/es/form/Form";
import DataContext from "../../../context/DataContext";
import { ItemDataProps } from "../../../types/interfaces";
import type { UploadProps } from "antd";

const format = "MM/DD/YYYY";

interface Props {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
}

export const SearchBarcodeComponent = ({
  itemSource,
}: {
  itemSource: ItemDataProps | null;
}) => {
  const { fetchBarcodeToOddo, compareOddoWithDb } = useContext(DataContext);
  const [values, setValues] = useState<Props>({
    dateFrom: null,
    dateTo: dayjs(),
  });
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const getBarcodes = (endpoint: "all" | "template") => {
    const itemSourceLabel = itemSource?.label
      ? itemSource?.label === "Non-Donation"
        ? "nonDonatedItem"
        : "donatedItem"
      : "donatedItem";
    values.dateFrom &&
      fetchBarcodeToOddo(
        endpoint,
        itemSourceLabel,
        values.dateFrom.format(format).toString(),
        values.dateTo?.format(format).toString()
      );
  };

  const props: UploadProps = {
    name: "file",
    onChange(info) {},
  };

  return (
    <>
      <Form name="search" layout="vertical" initialValues={values} form={form}>
        <Row gutter={16} justify="center">
          <Typography.Title level={5}>
            Item Source: {itemSource?.label || "Donation"}
          </Typography.Title>
        </Row>
        <Row gutter={16} justify="center">
          <Col span={12}>
            <Form.Item
              label="From"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date From",
                },
              ]}
            >
              <DatePicker
                name="dateFrom"
                format={format}
                value={values.dateFrom && dayjs(values.dateFrom)}
                style={{ width: "100%" }}
                allowClear={false}
                onChange={(_, dateString) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      dateFrom: dayjs(dateString),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="To"
              rules={[
                {
                  required: true,
                  message: "Please choose the Date to",
                },
              ]}
            >
              <DatePicker
                name="dateTo"
                format={format}
                style={{ width: "100%" }}
                allowClear={false}
                value={values.dateTo && dayjs(values.dateTo)}
                onChange={(_, dateString) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      dateTo: dayjs(dateString),
                    };
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center">
          <Col span={12}>
            <Form.Item>
              <Button
                type="primary"
                style={{ width: "100%" }}
                disabled={!values.dateFrom}
                onClick={() => getBarcodes("all")}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
