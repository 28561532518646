import { FloatButton, Tooltip } from "antd";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
} from "@ant-design/icons";

export const AppContent = ({ children }: { children: ReactNode }) => {
  const { onLogout, onOpenHelpModal, isAdmin } = useContext(AuthContext);
  const [isDashboard, setIsDashboard] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsDashboard(location.pathname === "/dashboard");
  }, [location]);

  const adminButton = () =>
    isDashboard ? (
      <Tooltip title="Admin">
        <FloatButton
          icon={<UserOutlined />}
          type="primary"
          onClick={() => navigate("/adm")}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Dashboard">
        <FloatButton
          icon={<HomeOutlined />}
          type="primary"
          onClick={() => navigate("/dashboard")}
        />
      </Tooltip>
    );

  return (
    <>
      <div className="content">{children}</div>
      <FloatButton.Group shape="circle" style={{ right: 40, bottom: "12vh" }}>
        {isAdmin ? adminButton() : <></>}
        <Tooltip title="Help">
          <FloatButton
            icon={<QuestionCircleOutlined />}
            type="primary"
            onClick={() => {
              onOpenHelpModal(true);
            }}
          />
        </Tooltip>
        <Tooltip title="Logout  ">
          <FloatButton
            icon={<LogoutOutlined />}
            type="default"
            onClick={onLogout}
          />
        </Tooltip>
      </FloatButton.Group>
    </>
  );
};
