import { ConfigProvider } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Pages } from "./navigation/routes/routes";
import { ProtectedRoute, PublicRoute } from "./navigation/layout";
import { config } from "./utils/config";
import AuthProvider from "./context/AuthProvider";
import DataProvider from "./context/DataProvider";

const App: React.FC = () => (
  <ConfigProvider theme={config}>
    <AuthProvider>
      <DataProvider>
        <Routes>
          {Pages.map((page) => (
            <Route
              key={page.path}
              path={page.path}
              element={
                page.protected ? (
                  <ProtectedRoute admin={page.admin}>
                    {page.component}
                  </ProtectedRoute>
                ) : (
                  <PublicRoute>{page.component}</PublicRoute>
                )
              }
            />
          ))}
        </Routes>
      </DataProvider>
    </AuthProvider>
  </ConfigProvider>
);

export default App;
