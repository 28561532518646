import { ReactNode } from "react";

interface Props {
  error?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}
export const DisplayComponent = ({ error, isLoading, children }: Props) => {
  if (error) return <div>Error</div>;
  if (isLoading) return <div>... loading</div>;
  return <>{children}</>;
};
