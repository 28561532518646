import {
  CategoryProps,
  DonorProps,
  GenderProps,
  ItemProps,
  NonDonatedItemProps,
  SelectorOptionProps,
  SizeProps,
  TypeProps,
} from "../types/interfaces";

export const ITEM_INITIAL_STATE: ItemProps = {
  gender: null,
  type: null,
  category: null,
  size: null,
  donor: null,
  // color: "",
  donorSource: null,
};

export const NON_DONATED_ITEM_INITIAL_STATE: NonDonatedItemProps = {
  supply: null,
  vendor: null,
};

export const GENDER_INITIAL_STATE: GenderProps = {
  id: 0,
  label: "",
  value: "",
  types: [],
};

export const TYPE_INITIAL_STATE: TypeProps = {
  id: 0,
  label: "",
  value: "",
  categories: [],
};

export const CATEGORY_INITIAL_STATE: CategoryProps = {
  id: 0,
  label: "",
  value: "",
  sizes: [],
};

export const SIZE_INITIAL_STATE: SizeProps = {
  id: 0,
  label: "",
  value: "",
};

export const DONOR_INITIAL_STATE: DonorProps = {
  id: 0,
  label: "",
  value: "",
  donorsource: [],
};

export const DONOR_SOURCE_INITIAL_STATE: SizeProps = {
  id: 0,
  label: "",
  value: "",
};

export const SELECTOR_OPTIONS_INITIAL_STATE: SelectorOptionProps = {
  types: [],
  categories: [],
  sizes: [],
  donors: [],
  donorSources: [],
};
