import React, { useContext, useEffect, useState } from "react";
import { Alert, Popover, Row, Table } from "antd";
import { ColComponent } from "../../components/ui/ColComponent";
import { PdfDrawer } from "../../components/pages";
import DataContext from "../../context/DataContext";
import { HelpModal } from "../../components/utils";
import { Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { BarcodeStringProps } from "../../types/interfaces";

const { Title } = Typography;

const helpContent = "These are all the barcodes generated in the system";

const columns: ColumnsType<BarcodeStringProps> = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "Barcode",
    dataIndex: "barcode",
    sorter: {
      compare: (a, b) => parseInt(a.barcode) - parseInt(b.barcode),
      multiple: 2,
    },
  },

  {
    title: "Gender",
    dataIndex: "gender",
    render: (_, row) => <>{row.gender && row.gender.label}</>,
  },
  {
    title: "Type",
    dataIndex: "type",
    render: (_, row) => <>{row.type && row.type.label}</>,
  },
  {
    title: "Size",
    dataIndex: "size",
    render: (_, row) => <>{row.size && row.size.label}</>,
  },
  {
    title: "Donor",
    dataIndex: "donor",
    render: (_, row) => <>{row.donor && row.donor.label}</>,
  },
  {
    title: "Donor Source",
    dataIndex: "donorSource",
    render: (_, row) => <>{row.donor_source && row.donor_source.label}</>,
  },
];

export const BarcodePage = () => {
  const {
    isLoading,
    isError,
    errorMessage,
    isSuccess,
    successMessage,
    barcodeList,
    getAllBarcodes,
  } = useContext(DataContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    getAllBarcodes();
  }, []);
  useEffect(() => {
    console.log(barcodeList);
  }, [barcodeList]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="pt-2 pb-8">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Title level={3}>Barcodes</Title>
      </div>

      <Row gutter={[12, 12]}>
        <ColComponent md={24} lg={24} xl={24}>
          <Table
            bordered
            loading={isLoading}
            columns={columns}
            dataSource={barcodeList}
            size="small"
            rowKey={"id"}
            scroll={{ y: "70vh", x: "100%" }}
            tableLayout="auto"
            rowSelection={rowSelection}
          />
        </ColComponent>
      </Row>
      <HelpModal content={helpContent} />
      <PdfDrawer />
      {isError && (
        <Alert
          message={errorMessage}
          banner
          type="error"
          closable
          className="alert"
        />
      )}
      {isSuccess && (
        <Alert
          message={successMessage}
          banner
          type="success"
          closable
          className="alert"
        />
      )}
    </div>
  );
};
