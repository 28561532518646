import React, { useEffect } from "react";
import { useState } from "react";
import DataContext from "./DataContext";

import dayjs from "dayjs";
import {
  ITEM_INITIAL_STATE,
  NON_DONATED_ITEM_INITIAL_STATE,
  SELECTOR_OPTIONS_INITIAL_STATE,
} from "../utils/data";
import {
  AttributeProps,
  BarcodeProps,
  BarcodeStringProps,
  DataProps,
  DonorSourceProps,
  ItemDataProps,
  ItemProps,
  NonDonatedItemProps,
  PropertyTypes,
  SizeProps,
} from "../types/interfaces";
import { useRequest } from "../hooks";
import JsBarcode from "jsbarcode";
import {
  exportBarcodes,
  exportTemplate,
  exportTemplateUpdate,
  exportBarcodeCreated,
} from "../utils/helpers";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const INITIAL_ERROR = "Oops, Something has ocurred!";
const INITIAL_SUCCESS = "Successful operation!";

const DataProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [itemSource, setItemSource] = useState<ItemDataProps | null>({
    id: 1,
    label: "Donation",
    value: "1",
  });
  const [barcodeList, setBarcodeList] = useState<BarcodeStringProps[]>([]);
  const [successMessage, setSucessMessage] = useState(INITIAL_SUCCESS);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(INITIAL_ERROR);
  const [itemValues, setItemValues] = useState<ItemProps>(ITEM_INITIAL_STATE);
  const [nonDonateditemValues, setNonDonatedItemValues] =
    useState<NonDonatedItemProps>(NON_DONATED_ITEM_INITIAL_STATE);
  const [barCodeString, setBarCodeString] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [dataOptions, setDataOptions] = useState<DataProps>({
    donatedItems: [],
    donorOptions: [],
    vendorOptions: [],
    nonDonatedItems: [],
    itemOptions: [],
  });
  const [selectorOptions, setSelectorOptions] = useState(
    SELECTOR_OPTIONS_INITIAL_STATE
  );
  const [titleModal, setTitleModal] = useState<keyof typeof PropertyTypes>("");
  const { handleRequest } = useRequest();

  const onChangeItemSource = (item: ItemDataProps) => setItemSource(item);

  useEffect(() => {
    setIsLoading(false);
    setErrorMessage(INITIAL_ERROR);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false);
    }, 5000);
  }, [isError, isSuccess]);

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // utils

  const getAllBarcodes = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<DataProps>({
      endpoint: "bar/all",
      options,
      onSuccess: (response) => {
        const data = response.data;
        setBarcodeList(data);
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const getData = ({ endpoint }: { endpoint: "options" }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<DataProps>({
      endpoint: `items/${endpoint}`,
      options,
      onSuccess: (response) => {
        const data = response.data;
        switch (endpoint) {
          case "options":
            setSelectorOptions(data);
            break;
          default:
            break;
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const getDashboardData = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<DataProps>({
      endpoint: "items/dashboard",
      options,
      onSuccess: (response) => {
        const data = response.data as DataProps;
        setDataOptions(data);
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const handleCreateBarcode = (
    itemSource: "donatedItem" | "nonDonatedItem",
    attributes: {
      gender_id?: number;
      type_id?: number;
      category_id?: number;
      size?: SizeProps[];
      donor_id?: number;
      donor_source?: DonorSourceProps[];
      supply_id?: number;
      vendor_id?: number;
    }
  ) => {
    setIsLoading(true);
    let data =
      itemSource === "donatedItem"
        ? {
            gender_id: attributes.gender_id,
            type_id: attributes.type_id,
            category_id: attributes.category_id,
            sizes:
              attributes &&
              attributes.size &&
              attributes.size?.map((size) => size.id),
            donor_id: attributes.donor_id,
            donorSources:
              attributes &&
              attributes.donor_source &&
              attributes.donor_source.map((donor_source) => donor_source.id),
          }
        : {
            supply_id: attributes.supply_id,
            vendor_id: attributes.vendor_id,
          };
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest<any>({
      endpoint: "bar",
      options,
      onSuccess: (response) => {
        if (response.data) {
          setIsSuccess(true);
          setSucessMessage(
            response.data.created ? "Barcode Created" : "Barcode Downloaded"
          );
          if (response.data.barcode && response.data.barcode.length > 0) {
            if (itemSource === "donatedItem") {
              exportTemplateUpdate(response.data.barcode);
            } else {
              exportTemplateUpdate(response.data.barcode);
            }
          } else {
            setIsError(true);
            setErrorMessage(
              "An error ocurred while created the barcode, please try again later"
            );
          }
        } else {
          setIsError(true);
          setErrorMessage(
            "An error ocurred while created the barcode, please try again later"
          );
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "An error ocurred while created the barcode, please try again later"
        );
      },
    });
  };

  const fetchBarcode = (itemSource: "donatedItem" | "nonDonatedItem") => {
    let bcString = "";
    let options: RequestInit = {
      method: "GET",
    };
    const { gender, type, size, donor, category, donorSource } = itemValues;
    const { supply, vendor } = nonDonateditemValues;
    const vendorOption = vendor === "Not Defined" ? null : vendor;
    const endpoint =
      itemSource === "donatedItem"
        ? `bar/?gender=${gender}&type=${type}&category=${category}&size=${size}&donor=${donor}&donor_source=${donorSource}`
        : `bar/?supply=${supply}&vendor=${vendorOption}`;
    handleRequest<BarcodeProps>({
      endpoint,
      options,
      onSuccess: (response) => {
        if (response.data) {
          setSucessMessage("Barcode Generated correctly");
          setIsSuccess(true);
          bcString = response.data.barcode;
          let canvas;
          canvas = document.createElement("canvas");
          JsBarcode(canvas, bcString, {
            format: "CODE128",
            displayValue: true,
            fontSize: 10,
          });
          setIsDrawerOpen(true);
          setBarCodeString(canvas.toDataURL());
        } else {
          setIsError(true);
          setErrorMessage("Not Barcodes found");
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setErrorMessage(
          "An error ocurred while fetching the barcode, please try again later"
        );
        setIsLoading(false);
      },
    });
  };
  const fetchBarcodeToOddo = (
    endpoint: "all" | "template",
    itemSource: "donatedItem" | "nonDonatedItem",
    dateFrom: string,
    dateTo?: string | null
  ) => {
    const dateToString = dateTo || dayjs();
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest<BarcodeProps>({
      endpoint: `bar/${endpoint}/?dateFrom=${dateFrom}&dateTo=${dateToString}&itemSource=${itemSource}`,
      options,
      onSuccess: (response) => {
        if (response.data) {
          if (response.data.length > 0) {
            switch (endpoint) {
              case "all":
                exportBarcodes(itemSource, response.data);
                setSucessMessage("Barcodes downloaded");
                break;
              case "template":
                exportTemplate(response.data);
                break;
              default:
                break;
            }

            setIsSuccess(true);
          } else {
            setErrorMessage("No Barcodes found");
            setIsError(true);
          }
        } else {
          setIsError(true);
          setErrorMessage("Not Barcodes found");
        }
        setIsLoading(false);
      },
      onError: () => {
        setIsError(true);
        setErrorMessage(
          "An error ocurred while fetching the barcode, please try again later"
        );
        setIsLoading(false);
      },
    });
  };

  const compareOddoWithDb = (data: any) => {
    if (!data) {
    } else {
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(data),
      };
      handleRequest<BarcodeProps>({
        endpoint: `bar/oddo`,
        options,
        onSuccess: (response) => {
          if (response.data) {
            //console.log(response.data);
            exportTemplateUpdate(response.data);
          } else {
            setIsError(true);
          }
          setIsLoading(false);
        },
        onError: () => {
          setIsError(true);
          setErrorMessage(
            "An error ocurred while comparing data, please try again later"
          );
          setIsLoading(false);
        },
      });
    }
  };

  const handleAddToDb = (item: {
    label: string;
    [x: string]: string | number;
  }) => {
    if (titleModal !== "") {
      setIsLoading(true);
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(item),
      };
      handleRequest<any>({
        endpoint: titleModal,
        options,
        onSuccess: (response) => {
          if (response.success) {
            setSucessMessage("Added to the database!");
            getData({ endpoint: "options" });
            setIsSuccess(true);
            getDashboardData();
            setIsLoading(false);
            onCancelToDb();
          }
        },
        onError: () => {
          setIsError(true);
          setIsLoading(false);
        },
      });
    }
  };

  const handleAddAttributes = ({
    attributeSelected,
    attributeToBeAdded,
    values,
  }: {
    attributeSelected: AttributeProps;
    attributeToBeAdded: string;
    values: string[];
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({
        attributeToBeAdded,
        values,
      }),
    };
    handleRequest<any>({
      endpoint: `${attributeSelected.name}/${attributeSelected.attribute.id}`,
      options,
      onSuccess: (response) => {
        if (response.success) {
          setIsSuccess(true);
          getDashboardData();
          getData({ endpoint: "options" });
          setIsLoading(false);
          onCancelToDb();
        }
      },
      onError: () => {
        setIsError(true);
        setIsLoading(false);
      },
    });
  };

  const onAddToDb = (property: keyof typeof PropertyTypes) => {
    setTitleModal(property);
    setAddModalOpen(true);
  };

  const onCancelToDb = () => {
    setTitleModal("");
    setAddModalOpen(false);
  };

  return (
    <DataContext.Provider
      value={{
        isLoading,
        isError,
        isSuccess,
        errorMessage,
        successMessage,
        itemValues,
        nonDonateditemValues,
        dataOptions,
        isDrawerOpen,
        barCodeString,
        addModalOpen,
        titleModal,
        selectorOptions,
        itemSource,
        barcodeList,
        getAllBarcodes,
        onChangeItemSource,
        getData,
        onClosePdfDrawer: () => setIsDrawerOpen(false),
        onGenerateBarcode: fetchBarcode,
        fetchBarcodeToOddo,
        compareOddoWithDb,
        onCreateBarcode: handleCreateBarcode,
        setItemValues,
        onChangeNonDonatedItemValues: (newValues: NonDonatedItemProps) =>
          setNonDonatedItemValues(newValues),
        onAddToDb,
        onCancelToDb,
        handleAddToDb,
        handleAddAttributes,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
