import React from "react";
import { Document } from "@react-pdf/renderer";
import { BarcodePage } from "../pages";
import { ItemProps } from "../../../types/interfaces";

export const BarcodeFile = ({
  barCodeString,
  qty,
  barcodeData,
}: {
  barCodeString: string;
  qty: number;
  barcodeData: ItemProps;
}) => {
  return (
    <Document>
      {Array.from({ length: qty }, (_, index) => index + 1).map((_, idx) => (
        <BarcodePage barCodeString={barCodeString} key={idx} />
      ))}
    </Document>
  );
};
