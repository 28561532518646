import { List } from "antd";
import React, { useContext } from "react";
import Styles from "./AdminComponents.module.css";
import { GeneralProps, SizeProps } from "../../../types/interfaces";
import clsx from "clsx";
import DataContext from "../../../context/DataContext";

interface Props {
  data: GeneralProps[];
  onItemClick?: (item: any) => void;
  active: any[];
  onItemDoubleClick?: (id: number) => void;
}

export const ItemListMultiSelect = ({
  data,
  onItemClick,
  active,
  onItemDoubleClick,
}: Props) => {
  const { isLoading } = useContext(DataContext);
  const handleItemClick = (item: GeneralProps) => {
    onItemClick && onItemClick(item);
  };

  const handleItemDoubleClick = (id: number) => {
    onItemDoubleClick && onItemDoubleClick(id);
  };
  return (
    <>
      <List
        loading={isLoading}
        size="small"
        className={Styles.list}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={() => handleItemClick(item)}
            onDoubleClick={() => handleItemDoubleClick(item.id)}
            className={clsx(
              Styles.item_list,
              active.map((selected) => selected?.id).includes(item?.id) &&
                Styles.item_list_active
            )}
          >
            {item.label}
          </List.Item>
        )}
      />
    </>
  );
};
