import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import DataContext from "../../../context/DataContext";
import {
  CategoryProps,
  DonorProps,
  DonorSourceProps,
  GenderProps,
  SizeProps,
  TypeProps,
} from "../../../types/interfaces";
import Styles from "./DashboardComponents.module.css";
import { BarcodeOutlined } from "@ant-design/icons";
import { ITEM_INITIAL_STATE } from "../../../utils/data";

export const DonatedItemsForm = () => {
  const [form] = Form.useForm();
  const { itemValues, setItemValues, onGenerateBarcode, dataOptions } =
    useContext(DataContext);
  const [buttonGenerateDisabled, setButtonGenerateDisabled] = useState(true);
  const [genderOptions, setGenderOptions] = useState<GenderProps[]>([]);
  const [donorOptions, setDonorOptions] = useState<DonorProps[]>([]);
  const [typeOptions, setTypeOptions] = useState<TypeProps[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryProps[]>([]);
  const [sizeOptions, setSizeOptions] = useState<SizeProps[]>([]);
  const [donorSourceOptions, setDonorSourceOptions] = useState<
    DonorSourceProps[]
  >([]);

  useEffect(() => {
    form.setFieldsValue(ITEM_INITIAL_STATE);
  }, []);

  useEffect(() => {
    if (dataOptions.donatedItems.length > 0) {
      const genderList = dataOptions.donatedItems.map((gender: GenderProps) => {
        return {
          ...gender,
          value: gender.id.toString(),
        };
      });
      setGenderOptions(genderList);
    }
    if (dataOptions.donorOptions.length > 0) {
      const donorList = dataOptions.donorOptions.map((donor: DonorProps) => {
        return {
          ...donor,
          value: donor.id.toString(),
        };
      });
      setDonorOptions(donorList);
    }
    setItemValues(ITEM_INITIAL_STATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      itemValues.gender &&
      itemValues.type &&
      itemValues.category &&
      itemValues.donor &&
      itemValues.donorSource
    )
      setButtonGenerateDisabled(false);
  }, [
    itemValues.gender,
    itemValues.category,
    itemValues.type,
    itemValues.donor,
    itemValues.donorSource,
  ]);

  const onChangeGender = (value: string, gender: GenderProps) => {
    setItemValues(() => {
      form.setFieldsValue({
        ...ITEM_INITIAL_STATE,
        gender: value,
      });
      return {
        ...ITEM_INITIAL_STATE,
        gender: value,
      };
    });
    const newList = gender.types.map((type: TypeProps) => {
      return {
        ...type,
        value: type.id,
      };
    });
    setTypeOptions(newList as any);
    setCategoryOptions([]);
    setSizeOptions([]);
  };

  const onChangeType = (value: string, type: TypeProps) => {
    const newList = type.categories.map((category: CategoryProps) => {
      return {
        ...category,
        value: category.id,
      };
    });
    setCategoryOptions(newList as any);
    setSizeOptions([]);
    setItemValues((prev) => {
      form.setFieldsValue({
        ...prev,
        type: value,
        category: null,
        size: null,
        donor: null,
      });
      return {
        ...prev,
        type: value,
        category: null,
        size: null,
        donor: null,
      };
    });
  };
  const onChangeCategory = (value: string, category: CategoryProps) => {
    const newList = category.sizes.map((size: SizeProps) => {
      return {
        ...size,
        value: size.id,
      };
    });
    setSizeOptions(newList as any);
    setItemValues((prev) => {
      form.setFieldsValue({
        ...prev,
        category: value,
        size: null,
      });
      return {
        ...prev,
        category: value,
        size: null,
      };
    });
  };

  const onChangeDonor = (value: string, donor: DonorProps) => {
    const newList = donor.donorsource.map((donorSource: DonorSourceProps) => {
      return {
        ...donorSource,
        value: donorSource.id,
      };
    });
    setDonorSourceOptions(newList as any);
    setItemValues((prev) => {
      form.setFieldsValue({
        ...prev,
        donor: value,
      });
      return {
        ...prev,
        donor: value,
      };
    });
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());
  return (
    <>
      <Form
        layout="vertical"
        initialValues={itemValues}
        form={form}
        onFinish={() => onGenerateBarcode("donatedItem")}
        className={Styles.form_item}
      >
        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="1. Gender"
              name="gender"
              rules={[{ required: true, message: "Gender Required" }]}
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a Gender"
                optionFilterProp="children"
                value={itemValues.gender}
                onChange={(value, gender) =>
                  onChangeGender(value, gender as GenderProps)
                }
                filterOption={filterOption}
                options={genderOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label={"2. Type"}
              name="type"
              rules={[{ required: true, message: "Type Required" }]}
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a Type"
                optionFilterProp="children"
                value={itemValues.type || null}
                onChange={(value, type) =>
                  onChangeType(value, type as TypeProps)
                }
                filterOption={filterOption}
                options={typeOptions}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="3. Category"
              name="category"
              rules={[{ required: true, message: "Category Required" }]}
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select Category"
                optionFilterProp="children"
                value={itemValues.category || null}
                onChange={(value, category) =>
                  onChangeCategory(value, category as CategoryProps)
                }
                filterOption={filterOption}
                options={categoryOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="4. Size"
              name="size"
              className={Styles.form_item}
              rules={[
                { required: sizeOptions.length > 0, message: "Size Required" },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a Size"
                optionFilterProp="children"
                value={itemValues.size}
                onChange={(value) => {
                  setItemValues((prev) => {
                    return {
                      ...prev,
                      size: value,
                    };
                  });
                }}
                filterOption={filterOption}
                options={sizeOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="5. Donor"
              name="donor"
              rules={[{ required: true, message: "Donor Required" }]}
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a Donor"
                optionFilterProp="children"
                value={itemValues.donor}
                onChange={(value, donor) => {
                  onChangeDonor(value, donor as DonorProps);
                }}
                filterOption={filterOption}
                options={donorOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="6. Donor Source"
              name="donorSource"
              rules={[{ required: true, message: "Donor Source Required" }]}
              className={Styles.form_item}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a Donor Source"
                optionFilterProp="children"
                value={itemValues.donorSource}
                onChange={(value) => {
                  setItemValues((prev) => {
                    return {
                      ...prev,
                      donorSource: value,
                    };
                  });
                }}
                filterOption={filterOption}
                options={donorSourceOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            className={Styles.form_item}
            icon={<BarcodeOutlined />}
            disabled={buttonGenerateDisabled}
          >
            Generate Barcode
          </Button>
        </Row>
      </Form>
    </>
  );
};
