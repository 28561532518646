import * as XLSX from "xlsx";

export const handleUploadFile = (e: any, next: (data: any) => void) => {
  try {
    e.preventDefault();
    var files = e.target?.files;
    var f = files[0];
    if (f) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target?.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataToCompare: any[] = [];
        dataParse.forEach((item: any, index) => {
          if (index === 0) {
            if (
              item[0] !== "id" &&
              item[1] !== "name" &&
              item[2] !== "product_template_variant_value_ids"
            )
              console.log("Error");
          }
          if (index !== 0) {
            let variants = item[2] && item[2].split(",");
            let productVariants: any = {};
            if (variants && variants.length > 0) {
              variants.forEach((value: any) => {
                let values = value.split(":");
                if (values && values.length > 0) {
                  productVariants[`${values[0]}`] = values[1].trim();
                }
              });
            }
            let newLine = {
              EXTERNAL_ID: item[0],
              Name: item[1],
              Category: item[1],
              ...productVariants,
            };
            dataToCompare.push(newLine);
          }
        });
        next(dataToCompare);
      };
      reader.readAsBinaryString(f);
    }
    next(null);
  } catch (e) {
    next(null);
  }
};
const handleExportExcel = (
  dataSheet1: Object[],
  dataSheet2?: Object[],
  name?: string
) => {
  try {
    const workbook = XLSX.utils.book_new();
    const worksheet1 = XLSX.utils.json_to_sheet(dataSheet1);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Sheet1");
    if (dataSheet2) {
      const worksheet2 = XLSX.utils.json_to_sheet(dataSheet2);
      XLSX.utils.book_append_sheet(workbook, worksheet2, "Sheet2");
    }
    XLSX.writeFile(workbook, name + ".xlsx");
  } catch (e) {}
};

export const exportBarcodes = (
  itemSource: "donatedItem" | "nonDonatedItem",
  barcodeList: any[]
) => {
  if (itemSource === "donatedItem") {
    const dataSheet1 = barcodeList.map((barcode) => {
      return {
        id: "",
        name: barcode.category?.label || "",
        product_template_variant_value_ids: `Size:${
          barcode.size?.label || ""
        },Donor Source:${barcode.donor_source?.label || ""}`,
        barcode: barcode.barcode,
      };
    });
    const dataSheet2 = barcodeList.map((barcode) => {
      return {
        id: "",
        name: barcode.category?.label,
        product_template_variant_value_ids: `Size:${
          barcode.size?.label || ""
        },Donor Source:${barcode.donor_source?.label || ""}`,
        barcode: barcode.barcode,
      };
    });
    handleExportExcel(dataSheet1, dataSheet2, "Barcodes Donated Items");
  } else {
    const dataSheet1 = barcodeList.map((barcode) => {
      return {
        "Supply Name": barcode.supply?.label || "",
        Vendor: barcode.vendor?.label || "",
        "Product Attributes / Attribute": "Supply/Vendor",
        "Product Attributes / Values": `Supply/${barcode.vendor?.label || ""}`,
        Type: "Storable Product",
      };
    });
    const dataSheet2 = barcodeList.map((barcode) => {
      return {
        id: "",
        name: barcode.supply?.label || "",
        product_template_variant_value_ids: `Vendor:${
          barcode.vendor?.label || ""
        }`,
        barcode: barcode.barcode,
      };
    });
    handleExportExcel(dataSheet1, dataSheet2, "Barcodes Supplies");
  }
};

export const exportBarcodeCreated = (
  itemSource: "donatedItem" | "nonDonatedItem",
  barcodeList: any[],
  created: boolean
) => {
  if (itemSource === "donatedItem") {
    const dataSheet1 = barcodeList.map((barcode) => {
      return {
        id: "",
        name: barcode.category?.label || "",
        Size: barcode.size?.label || "",
        Donor: barcode.donor?.label || "",
        "Donor Source": barcode.donor_source?.label || "",
        barcode: barcode.barcode,
      };
    });
    handleExportExcel(
      dataSheet1,
      undefined,
      created ? "Barcodes Created" : "Barcode Info"
    );
  } else {
    const dataSheet1 = barcodeList.map((barcode) => {
      return {
        "Supply Name": barcode.supply?.label || "",
        Vendor: barcode.vendor?.label || "",
        "Product Attributes / Attribute": "Supply/Vendor",
        "Product Attributes / Values": `Supply/${barcode.vendor?.label || ""}`,
        Type: "Storable Product",
      };
    });
    const dataSheet2 = barcodeList.map((barcode) => {
      return {
        id: "",
        name: barcode.supply?.label || "",
        product_template_variant_value_ids: `Vendor:${
          barcode.vendor?.label || ""
        }`,
        barcode: barcode.barcode,
      };
    });
    handleExportExcel(dataSheet1, dataSheet2, "Barcodes Supplies");
  }
};

export const exportTemplate = (templateList: any[]) => {
  const dataSheet1: any[] = [];
  templateList.forEach((item) => {
    Object.keys(item.Attribute).forEach((attribute, index) => {
      if (item.Attribute[attribute].length > 0) {
        let newLine = {
          EXTERNAL_ID: "",
          Name: "",
          Attribute: "",
          Values: "",
          Type: "",
        };
        if (index === 0) {
          newLine.Name = item.Name;
        }
        if (attribute === "Size") {
          if (item.Attribute["Size"].toString() !== "") {
            newLine.Attribute = attribute as string;
            newLine.Values = item.Attribute[attribute]
              .filter((value: any) => value)
              .toString();
          }
        } else {
          newLine.Attribute = attribute as string;
          newLine.Values = item.Attribute[attribute].toString();
        }

        if (index === 0) {
          newLine.Type = item.Type;
        }
        dataSheet1.push(newLine);
      }
    });
  });
  handleExportExcel(dataSheet1, undefined, "Create Template");
};

export const exportTemplateUpdate = (templateList: any[]) => {
  handleExportExcel(templateList, undefined, "Update Template");
};
