import { AdminPage, BarcodePage, Dashboard, LoginPage } from "../../pages";
import { PageProps } from "../../types/interfaces";

export const routes = {
  login: "/",
  dashboard: "/dashboard",
  admin: "/adm",
  barcode: "/barcode",
  help: "/help",
};

export const Pages: PageProps[] = [
  {
    path: routes.login,
    protected: false,
    admin: false,
    component: <LoginPage />,
  },
  {
    path: routes.admin,
    protected: true,
    admin: true,
    component: <AdminPage />,
  },
  {
    path: routes.dashboard,
    protected: true,
    admin: true,
    component: <Dashboard />,
  },
  {
    path: routes.barcode,
    protected: true,
    admin: true,
    component: <BarcodePage />,
  },
];
