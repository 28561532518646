import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import DataContext from "../../../context/DataContext";
import { ItemDataProps } from "../../../types/interfaces";
import Styles from "./DashboardComponents.module.css";

export const ItemForm = ({
  onChangeItem,
}: {
  onChangeItem: (item: ItemDataProps) => void;
}) => {
  const { dataOptions, itemSource } = useContext(DataContext);
  const [itemOptions, setItemOptions] = useState<ItemDataProps[]>();

  useEffect(() => {
    if (dataOptions.itemOptions.length > 0) {
      setItemOptions(
        dataOptions.itemOptions.map((item) => {
          return {
            ...item,
            value: item.id.toString(),
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());
  return (
    <>
      <Select
        style={{ width: 150 }}
        showSearch
        size="large"
        placeholder="Select Item Source"
        optionFilterProp="children"
        onChange={(_, item) => onChangeItem(item as ItemDataProps)}
        filterOption={filterOption}
        options={itemOptions}
      />
    </>
  );
};
