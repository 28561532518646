import React, { useContext, useState } from "react";
import { Alert, Popover, Row } from "antd";
import { ColComponent } from "../../components/ui/ColComponent";
import {
  DonatedItemsForm,
  ItemForm,
  NonDonatedItemsForm,
  PdfDrawer,
} from "../../components/pages";
import Styles from "./Dashboard.module.css";
import DataContext from "../../context/DataContext";
import { HelpModal } from "../../components/utils";
import { ItemDataProps } from "../../types/interfaces";
import { Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const helpContent = "Choose one option in every selector to generate Bar Code";

export const Dashboard = () => {
  const {
    isLoading,
    isError,
    errorMessage,
    isSuccess,
    successMessage,
    itemSource,
  } = useContext(DataContext);
  // const [itemSource, setItemSource] = useState<ItemDataProps | null>(null);

  const content = (
    <div>
      <Paragraph>Step 1: Select item source</Paragraph>
      <Paragraph>Step 2: Select attributes</Paragraph>
      <Paragraph>Step 3: Click on Generate Barcode</Paragraph>
    </div>
  );

  return (
    <div className="pt-2 pb-8">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Title level={3}>Generate Barcode</Title>
        <span style={{ marginLeft: "5px" }}>
          <Popover content={content} title="Hint">
            <QuestionCircleOutlined />
          </Popover>
        </span>
      </div>

      <Row gutter={[12, 12]}>
        <ColComponent md={24} lg={24} xl={24}>
          {!isLoading ? (
            <>
              {itemSource && (
                <>
                  {itemSource.label === "Donation" ? (
                    <DonatedItemsForm />
                  ) : (
                    <NonDonatedItemsForm />
                  )}
                </>
              )}
            </>
          ) : (
            <div className={Styles.spinner}></div>
          )}
        </ColComponent>
      </Row>
      <HelpModal content={helpContent} />
      <PdfDrawer />
      {isError && (
        <Alert
          message={errorMessage}
          banner
          type="error"
          closable
          className="alert"
        />
      )}
      {isSuccess && (
        <Alert
          message={successMessage}
          banner
          type="success"
          closable
          className="alert"
        />
      )}
    </div>
  );
};
