import React from "react";

interface Props {
  isLoggedIn: boolean;
  isSuccess: boolean;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  isAdmin: boolean;
  isHelpModalOpen: boolean;
  onOpenHelpModal: (open: boolean) => void;
  onLogin: (username: string, password: string) => void;
  onLogout: () => void;
  validateToken: () => boolean;
}

const AuthContext = React.createContext<Props>({
  isLoggedIn: false,
  isSuccess: false,
  isError: false,
  isLoading: false,
  errorMessage: "",
  isAdmin: false,
  isHelpModalOpen: false,
  onOpenHelpModal: () => {},
  onLogin: () => {},
  onLogout: () => {},
  validateToken: () => false,
});

export default AuthContext;
