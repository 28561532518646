import React from "react";
import { Image, Page, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 40px",
  },
  barcode: {
    border: "4px solid #000",
    padding: 10,
  },
});

export const BarcodePage = ({ barCodeString }: { barCodeString: string }) => {
  return (
    <Page style={styles.page} orientation="landscape" size={[200, 400]}>
      <div style={styles.barcode}>
        <Image src={barCodeString} />
      </div>
    </Page>
  );
};
