import { Button, Tooltip } from "antd";
import React from "react";
import Styles from "./AdminComponents.module.css";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export const ButtonList = ({ title, onClick, disabled = false }: Props) => {
  return (
    <div className={Styles.title}>
      {title}
      <Tooltip title={"Add " + title}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginLeft: 20 }}
          onClick={onClick}
          disabled={disabled}
        />
      </Tooltip>
    </div>
  );
};
