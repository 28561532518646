import { Button, Col, Popover, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ButtonList } from "./ButtonList";
import { ItemList } from "./ItemList";
import Styles from "./AdminComponents.module.css";
import DataContext from "../../../context/DataContext";
import {
  AttributeProps,
  CategoryProps,
  DonorProps,
  DonorSourceProps,
  GenderProps,
  GeneralProps,
  SizeProps,
  TypeProps,
} from "../../../types/interfaces";
import {
  CATEGORY_INITIAL_STATE,
  DONOR_INITIAL_STATE,
  GENDER_INITIAL_STATE,
  TYPE_INITIAL_STATE,
} from "../../../utils/data";
import { AddToDbForm } from "../dashboard/AddToDbForm";
import { CustomModal } from "../../ui";
import { ItemListMultiSelect } from "./ItemListMultiSelect";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

const content = (
  <div>
    <Paragraph>
      Select one or more Attributes from this list to create all variants
    </Paragraph>
  </div>
);

export const DonatedItemsAdminForm = () => {
  const {
    dataOptions,
    addModalOpen,
    titleModal,
    selectorOptions,
    isLoading,
    onCancelToDb,
    onAddToDb,
    onCreateBarcode,
    handleAddToDb,
    handleAddAttributes,
  } = useContext(DataContext);
  const [genderSelected, setGenderSelected] =
    useState<GenderProps>(GENDER_INITIAL_STATE);
  const [typeSelected, setTypeSelected] =
    useState<TypeProps>(TYPE_INITIAL_STATE);
  const [categorySelected, setCategorySelected] = useState<CategoryProps>(
    CATEGORY_INITIAL_STATE
  );
  const [sizeSelected, setSizeSelected] = useState<SizeProps[]>([]);
  const [donorSourceSelected, setDonorSourceSelected] = useState<
    DonorSourceProps[]
  >([]);
  const [donorSelected, setDonorSelected] =
    useState<DonorProps>(DONOR_INITIAL_STATE);
  const [typeOptions, setTypeOptions] = useState<TypeProps[]>([]);
  const [donorSourceOptions, setDonorSourceOptions] = useState<
    DonorSourceProps[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<CategoryProps[]>([]);
  const [sizeOptions, setSizeOptions] = useState<SizeProps[]>([]);
  const [attributeSelected, setAttributeSelected] = useState<AttributeProps>({
    name: "gender",
    attribute: {
      id: 0,
      label: "",
      value: "",
    },
  });
  const [propSelected, setPropSelected] = useState<GeneralProps | null>(null);
  const [isReadyToCreateBarcode, setIsReadyToCreateBarcode] = useState(false);
  const [options, setOptions] = useState([{ label: "", value: "" }]);

  useEffect(() => {
    let property =
      titleModal === "type"
        ? genderSelected
        : titleModal === "category"
        ? typeSelected
        : titleModal === "size"
        ? categorySelected
        : titleModal === "donorSource"
        ? donorSelected
        : null;
    setPropSelected(property);
  }, [
    titleModal,
    genderSelected,
    typeSelected,
    categorySelected,
    donorSelected,
  ]);

  useEffect(() => {
    switch (titleModal) {
      case "gender":
        setOptions([]);
        break;
      case "type":
        setOptions(
          selectorOptions.types?.map((item) => ({
            label: item.label,
            value: item.id,
          }))
        );
        break;
      case "category":
        setOptions(
          selectorOptions.categories?.map((item) => ({
            label: item.label,
            value: item.id,
          }))
        );
        break;
      case "size":
        setOptions(
          selectorOptions.sizes?.map((item) => ({
            label: item.label,
            value: item.id,
          }))
        );
        break;
      case "donor":
        setOptions(
          selectorOptions.donors?.map((item) => ({
            label: item.label,
            value: item.id,
          }))
        );
        break;
      case "donorSource":
        setOptions(
          selectorOptions.donorSources?.map((item) => ({
            label: item.label,
            value: item.id,
          }))
        );
        break;

      default:
        break;
    }
  }, [titleModal]);

  useEffect(() => {
    console.log(sizeOptions);
    if (
      genderSelected.id &&
      typeSelected.id &&
      categorySelected.id &&
      donorSelected.id &&
      donorSourceSelected.length > 0
    ) {
      if (sizeOptions?.length > 0) {
        sizeSelected?.length > 0
          ? setIsReadyToCreateBarcode(true)
          : setIsReadyToCreateBarcode(false);
      } else {
        sizeSelected.length === 0 && setIsReadyToCreateBarcode(true);
      }
    } else {
      setIsReadyToCreateBarcode(false);
    }
  }, [
    genderSelected,
    typeSelected,
    categorySelected,
    sizeSelected,
    donorSelected,
    donorSourceSelected,
    sizeOptions,
  ]);

  useEffect(() => {
    if (genderSelected.id !== 0) {
      const genderPreSelected = dataOptions.donatedItems.filter(
        (gender) => gender.id === genderSelected.id
      );
      onChangeGender(genderPreSelected[0]);
      if (typeSelected.id !== 0) {
        const typePreSelected = genderPreSelected[0].types.filter(
          (type) => type.id === typeSelected.id
        );
        onChangeType(typePreSelected[0]);
        if (categorySelected.id !== 0) {
          const categoryPreselected = typePreSelected[0].categories.filter(
            (category) => category.id === categorySelected?.id
          );
          onChangeCategory(categoryPreselected[0]);
          let sizePreselected = categoryPreselected[0].sizes.filter(
            (size) => size.id === sizeSelected[0]?.id
          );
          if (sizePreselected[0]?.id !== 0) {
            onChangeSize(sizePreselected[0]);
          }
        }
      }
    }
    if (donorSelected.id !== 0) {
      const donorPreselected = dataOptions.donorOptions.filter(
        (donor) => donor.id === donorSelected.id
      );
      if (donorPreselected[0]?.id) {
        onChangeDonor(donorPreselected[0]);
        const donorSourcePreselected = donorPreselected[0].donorsource.filter(
          (donorSource) => donorSource?.id === donorSourceSelected[0]?.id
        );
        if (donorSourcePreselected[0]?.id) {
          onChangeDonorSource(donorSourcePreselected[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOptions.donatedItems]);

  const onChangeGender = (gender: GenderProps) => {
    setAttributeSelected({ name: "gender", attribute: gender });
    setGenderSelected(gender);
    setTypeOptions(gender.types);
    setCategoryOptions([]);
    setSizeOptions([]);
    setTypeSelected(TYPE_INITIAL_STATE);
    setCategorySelected(CATEGORY_INITIAL_STATE);
    setSizeSelected([]);
  };

  const onChangeType = (type: TypeProps) => {
    setAttributeSelected({ name: "type", attribute: type });
    setTypeSelected(type);
    setCategoryOptions(type.categories);
    setSizeOptions([]);
    setCategorySelected(CATEGORY_INITIAL_STATE);
    setSizeSelected([]);
  };
  const onChangeCategory = (category: CategoryProps) => {
    setAttributeSelected({ name: "category", attribute: category });
    setCategorySelected(category);
    setSizeOptions(category.sizes);
    setSizeSelected([]);
  };

  const onChangeSize = (size: SizeProps) => {
    // setAttributeSelected({ name: "size", attribute: size });
    // setSizeSelected(size);
    if (size) {
      setSizeSelected((prev) => {
        return [...prev, size];
      });
    }
  };

  const onChangeDonor = (donor: DonorProps) => {
    setAttributeSelected({ name: "donor", attribute: donor });
    setDonorSelected(donor);
    setDonorSourceOptions(donor.donorsource);
    setDonorSourceSelected([]);
  };

  const onChangeDonorSource = (donorSource: DonorSourceProps) => {
    // setAttributeSelected({ name: "donor_source", attribute: donorSource });
    // setDonorSourceSelected(donorSource);
    setDonorSourceSelected((prev) => {
      return [...prev, donorSource];
    });
  };

  const createBarcode = () => {
    if (
      genderSelected.id &&
      typeSelected.id &&
      categorySelected.id &&
      donorSelected.id &&
      donorSourceSelected[0].id
    ) {
      onCreateBarcode("donatedItem", {
        gender_id: genderSelected.id,
        type_id: typeSelected.id,
        category_id: categorySelected.id,
        size: sizeSelected,
        donor_id: donorSelected.id,
        donor_source: donorSourceSelected,
      });
    }
  };

  const addDataToDb = (label: string) => {
    switch (titleModal) {
      case "gender":
        handleAddToDb({ label });
        break;
      case "type":
        handleAddToDb({ label, gender_id: genderSelected.id });
        break;
      case "category":
        handleAddToDb({ label, type_id: typeSelected.id });
        break;
      case "size":
        handleAddToDb({ label, category_id: categorySelected.id });
        break;
      case "donor":
        handleAddToDb({ label });
        break;
      case "donorSource":
        handleAddToDb({ label, donor_id: donorSelected.id });
        break;
      default:
        break;
    }
  };

  const onUnSelectSize = (id: number) => {
    const newSelectedSizes = sizeSelected;
    const opt = newSelectedSizes.filter((size) => size.id !== id);
    setSizeSelected(opt);
  };
  const onUnselectDonorSource = (id: number) => {
    const newSelectedDonorSources = donorSourceSelected;
    const opt = newSelectedDonorSources.filter(
      (donorSource) => donorSource.id !== id
    );
    setDonorSourceSelected(opt);
  };
  return (
    <div className={Styles.AdminPage}>
      <Row gutter={[16, 24]} justify="center" align="middle">
        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col span={24}>
              <ButtonList title="Gender" onClick={() => onAddToDb("gender")} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemList
                data={dataOptions.donatedItems}
                onItemClick={onChangeGender}
                active={genderSelected}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col span={24}>
              <ButtonList title="Type" onClick={() => onAddToDb("type")} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemList
                data={typeOptions}
                onItemClick={onChangeType}
                active={typeSelected}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col span={24}>
              <ButtonList
                title="Categories"
                onClick={() => onAddToDb("category")}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemList
                data={categoryOptions}
                onItemClick={onChangeCategory}
                active={categorySelected}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Row align={"middle"}>
            <Col span={22}>
              <ButtonList title="Sizes" onClick={() => onAddToDb("size")} />
            </Col>
            <Col span={2}>
              <span>
                <Popover content={content}>
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemListMultiSelect
                data={sizeOptions}
                onItemClick={onChangeSize}
                active={sizeSelected}
                onItemDoubleClick={onUnSelectSize}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Row>
            <Col span={24}>
              <ButtonList title="Donors" onClick={() => onAddToDb("donor")} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemList
                data={dataOptions.donorOptions}
                onItemClick={onChangeDonor}
                active={donorSelected}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Row align={"middle"}>
            <Col span={22}>
              <ButtonList
                title="Donor Source"
                onClick={() => onAddToDb("donorSource")}
              />
            </Col>
            <Col span={2}>
              <span>
                <Popover content={content}>
                  <QuestionCircleOutlined />
                </Popover>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemListMultiSelect
                data={donorSourceOptions}
                onItemClick={onChangeDonorSource}
                active={donorSourceSelected}
                onItemDoubleClick={onUnselectDonorSource}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 24]} justify="center">
            <Button
              loading={isLoading}
              type="primary"
              disabled={!isReadyToCreateBarcode}
              onClick={createBarcode}
            >
              Create Barcode
            </Button>
          </Row>
        </Col>
      </Row>
      <CustomModal
        title={
          propSelected?.label
            ? "Add " + titleModal + " to " + propSelected?.label
            : "Add " + titleModal + " to Database"
        }
        isModalOpen={addModalOpen}
        handleOk={onCancelToDb}
        handleCancel={onCancelToDb}
        footer={null}
        width={600}
      >
        <AddToDbForm
          attributeSelected={propSelected}
          addDataToDb={(label: string, next: () => void) => {
            addDataToDb(label);
            next();
          }}
          addExistingAttributes={(values) =>
            handleAddAttributes({
              attributeSelected: attributeSelected,
              attributeToBeAdded: titleModal,
              values,
            })
          }
          options={options}
        />
      </CustomModal>
    </div>
  );
};
