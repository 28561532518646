import React from "react";
import {
  AttributeProps,
  BarcodeStringProps,
  DataProps,
  DonorSourceProps,
  ItemDataProps,
  ItemProps,
  NonDonatedItemProps,
  PropertyTypes,
  SelectorOptionProps,
  SizeProps,
} from "../types/interfaces";
import {
  ITEM_INITIAL_STATE,
  NON_DONATED_ITEM_INITIAL_STATE,
  SELECTOR_OPTIONS_INITIAL_STATE,
} from "../utils/data";

interface Props {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorMessage: string;
  successMessage: string;
  itemValues: ItemProps;
  nonDonateditemValues: NonDonatedItemProps;
  dataOptions: DataProps;
  isDrawerOpen: boolean;
  barCodeString: string;
  addModalOpen: boolean;
  titleModal: keyof typeof PropertyTypes;
  selectorOptions: SelectorOptionProps;
  itemSource: ItemDataProps | null;
  barcodeList: BarcodeStringProps[];
  getAllBarcodes: () => void;
  onChangeItemSource: (item: ItemDataProps) => void;
  getData: ({ endpoint }: { endpoint: "options" }) => void;
  onClosePdfDrawer: () => void;
  onGenerateBarcode: (itemSource: "donatedItem" | "nonDonatedItem") => void;
  fetchBarcodeToOddo: (
    endpoing: "all" | "template",
    itemSource: "donatedItem" | "nonDonatedItem",
    dateFrom: string,
    dateTo?: string | null
  ) => void;
  compareOddoWithDb: (data: any) => void;
  onCreateBarcode: (
    itemSource: "donatedItem" | "nonDonatedItem",
    attributes: {
      gender_id?: number;
      type_id?: number;
      category_id?: number;
      size?: SizeProps[];
      donor_id?: number;
      donor_source?: DonorSourceProps[];
      supply_id?: number;
      vendor_id?: number;
    }
  ) => void;
  setItemValues: React.Dispatch<React.SetStateAction<ItemProps>>;
  onChangeNonDonatedItemValues: (newValues: NonDonatedItemProps) => void;
  onAddToDb: (property: keyof typeof PropertyTypes) => void;
  onCancelToDb: () => void;
  handleAddToDb: (
    item: {
      label: string;
      [x: string]: string | number;
    },
    endpoint?: string
  ) => void;
  handleAddAttributes: ({
    attributeSelected,
    attributeToBeAdded,
    values,
  }: {
    attributeSelected: AttributeProps;
    attributeToBeAdded: string;
    values: string[];
  }) => void;
}

const DataContext = React.createContext<Props>({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: "",
  successMessage: "",
  itemValues: ITEM_INITIAL_STATE,
  nonDonateditemValues: NON_DONATED_ITEM_INITIAL_STATE,
  dataOptions: {
    donatedItems: [],
    donorOptions: [],
    nonDonatedItems: [],
    vendorOptions: [],
    itemOptions: [],
  },
  selectorOptions: SELECTOR_OPTIONS_INITIAL_STATE,
  isDrawerOpen: false,
  barCodeString: "",
  titleModal: "",
  addModalOpen: false,
  itemSource: null,
  barcodeList: [],
  getAllBarcodes: () => {},
  onChangeItemSource: () => {},
  getData: () => {},
  onClosePdfDrawer: () => {},
  onGenerateBarcode: () => {},
  fetchBarcodeToOddo: () => {},
  compareOddoWithDb: () => {},
  onCreateBarcode: () => {},
  setItemValues: () => {},
  onChangeNonDonatedItemValues: () => {},
  onAddToDb: () => {},
  onCancelToDb: () => {},
  handleAddToDb: () => {},
  handleAddAttributes: () => {},
});

export default DataContext;
