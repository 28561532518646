import { Drawer, Grid, InputNumber } from "antd";
import React, { useContext, useState } from "react";
import { PDFView } from "../../pdf/pdfView";
import DataContext from "../../../context/DataContext";

export const PdfDrawer = () => {
  const [qty, setQty] = useState(1);
  const { isDrawerOpen, onClosePdfDrawer, barCodeString, itemValues } =
    useContext(DataContext);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const width = xs ? "90%" : "35%";

  return (
    <>
      <Drawer
        title="Barcode"
        width={width}
        onClose={onClosePdfDrawer}
        open={isDrawerOpen}
        extra={
          <InputNumber
            name="ItemQty"
            placeholder="Item Qty"
            min={1}
            value={qty}
            onChange={(newValue) => {
              if (newValue) setQty(newValue);
            }}
          />
        }
      >
        <PDFView
          barCodeString={barCodeString}
          qty={qty}
          barcodeData={itemValues}
        />
      </Drawer>
    </>
  );
};
